<template>
  <div class="box">
    <div class="box_container">
      <div style="height:10px;"></div>
      <!-- 下拉框 -->
      <div class="head">
        <div></div>
        <div class="flex">
          <div class="flex head_dropDown">
            <span>{{ $t('message.group') }}</span>
            <el-select class="head_select" v-model="groupId" filterable :placeholder="$t('message.pleaseselectagroup')">
              <el-option v-for="item in group" :key="item.Cg_ID" :label="item.Cg_Name" :value="item.Cg_ID">
              </el-option>
            </el-select>
          </div>
          <div class="flex head_dropDown">
            <span>{{ $t('message.users') }}</span>
            <el-select class="head_select" v-model="userId" filterable :placeholder="$t('message.Pleaseselectauser')">
              <el-option v-for="item in user" :key="item.User_ID" :label="item.User_Name" :value="item.User_ID">
              </el-option>
            </el-select>
          </div>
          <div class="flex head_dropDown">
            <span>{{ $t('message.date') }}</span>
            <el-date-picker v-model="valueStart" type="daterange" range-separator="~" :picker-options="pickerOptions"
              :start-placeholder="$t('message.startrange')" :end-placeholder="$t('message.endrange')" align="right">
            </el-date-picker>
          </div>
          <div class="head_search head_dropDown mouse" @click="search()">
            {{ $t('message.search') }}
          </div>
          <download-excel v-show="false" class="head_but" ref="downloadntb" :data="excelData.wb" :fields="json_fields"
            :name="downloadName">
            <button type="primary" icon="download">{{ $t('message.Batchdownload') }}</button>
          </download-excel>
          <div class="head_but mouse" @click="dowloan()">
            {{ $t('message.Batchdownload') }}
          </div>
        </div>
      </div>
      <!-- 下拉结束 -->

      <!-- 表格 -->
      <div class="table table_one">
        <history-table :bool="bool" :tableData="tableData" :tableHead="tableHead" ref="history"
          @getSelectiondata="getSelectiondata">
          <el-table-column slot="operation" :label="$t('message.operate')" width="300">
            <template slot-scope="scope">
              <div class="table_box">
                <span class="table_but mouse" @click="playLy(scope.row, scope.$index)">{{
                  $t('message.Recordingplayback') }}</span>
                <span style="color: #2776c3;margin-right: 10px;">/</span>
                <span class="table_but mouse" @click="download(scope.row)">{{ $t('message.download') }}</span>
              </div>
            </template>
          </el-table-column>
        </history-table>
      </div>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination class="pagination_bottom" :current-page="paging.pageNo" background :page-sizes="[20, 40, 60, 80]"
          layout="total,prev, pager, next,jumper,slot, sizes" @size-change="sizeChange" @prev-click="prevClick"
          @next-click="nextClick" @current-change="currentChange" :total="paging.total">
          <span class="confirbtn" @click="comfir()">{{ $t('message.confirm') }}</span>
        </el-pagination>
      </div>
    </div>
    <dowloanpop :showcontent="showcontent" :tipstext="tipstext" @confirmclosepop="confirmclosepop" @closepop="closepop">
    </dowloanpop>
  </div>
</template>

<script src=""></script>
<script>
import {
  queryGroupName,
  queryUserName,
  querySoundRecord,
  queryAudio
} from "@/administration/history.js";
import historyTable from "@/components/history/table.vue";
import dowloanpop from "@/components/history/deletepop.vue";
import beasconfig from "@/api/beasconfig";
var apiAudioUrl = beasconfig.apiAudioUrl;
export default {
  components: {
    historyTable,
    dowloanpop
  },
  // props: {

  // },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          let curDate = new Date().getTime();
          let three = 90 * 24 * 3600 * 1000;
          let threeMonths = curDate - three;
          return time.getTime() > Date.now() || time.getTime() < threeMonths;
        }
      },
      valueStart: [], //开始时间
      group: [
        //群组
      ],
      groupId: "", //群组id
      user: [
        //用户
      ],
      userId: "", //用户id
      stateData: [
        //状态
        {
          value: -1,
          label: this.$t('message.Pleaseselectastatus')
        },
        {
          value: 0,
          label: this.$t('message.Unprocessed')
        },
        {
          value: 2,
          label: this.$t('message.Processed')
        }
      ],
      state: "",
      radiobroadcastData: [
        //广播类型
        {
          value: -1,
          label: this.$t('message.Pleaseselectatype')
        },
        {
          value: 1,
          label: this.$t('message.text')
        },
        {
          value: 3,
          label:this.$t('message.voice')
        }
      ],
      radiobroadcastType: "",
      currentPage4: 4,
      paging: {
        total: 0,
        currentPage: 1,
        pageNo: 1,
        pageSize: 20
      },
      sos: {
        clUser: "", //发送人
        fsUser: "", //处理人
        fsStartTime: "", //发送开始时间
        clStartTime: "" //处理结束时间
      },
      // sos
      userInfo: {}, //用户信息
      chooselist: [], //选中数据
      tableData: [], //table数据
      tableHead: [
        {
          lable: this.$t('message.group'),
          prop: "groupName"
        },
        {
          lable: this.$t('message.userName'),
          prop: "userName"
        },
        {
          lable: this.$t('message.date'),
          prop: "createAt"
        }
      ], //表头数据
      bool: false,
      historyIndex: 0,
      music: null,
      showcontent: false,
      tipstext: this.$t('message.Areyousureyouwant'),
      ossMap: {},
      excelData: {
        ly: [],
        wb: []
      },
      json_fields: {},
      downloadName: this.$t('message.Record'),
      timeNum: 0,
      temporaryTime: null
    };
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.getQueryGroupName();
    this.getQueryUserName();
    this.getSoundRecord();
  },
  beforeCreate: function() {},
  beforeDestroy() {
    let i = this.tableData.findIndex(item => item.bool == true);
    if (i >= 0) {
      this.$set(this.tableData[i], "bool", false);
      // this.$forceUpdate()
      this.music.pause();
      this.eliminateTime();
      this.tableData[i].bool = false;
      this.music = null;
    }
  },
  mounted() {
    //  this.$refs.myVideo.addEventListener("play", () => {
    //     console.log("video is playing");
    // });
    // // 监听视频暂停
    // this.$refs.myVideo.addEventListener("pause", () => {
    //     console.log("video is stop");
    // });
  },
  methods: {
    getSoundRecord() {
      //获取录音记录
      let that = this;
      let params = {
        oId: that.userInfo.User_CompanyID,
        pageNo: that.paging.pageNo,
        pageSize: that.paging.pageSize
      };
      if (that.groupId != "" && that.groupId > 0) {
        params.gId = that.groupId; //群组id
      }
      if (that.userId != "" && that.userId > 0) {
        //用戶id
        params.uId = that.userId;
      }
      if (that.valueStart != "" && that.valueStart != null) {
        //开始时间
        let value1 =
          this.$moment(this.valueStart[0]).format("YYYY-MM-DD") + " 00:00:00";
        let value2 =
          this.$moment(this.valueStart[1]).format("YYYY-MM-DD") + " 23:59:59";
        params.startDate = this.$moment(value1).valueOf();
        params.endDate = this.$moment(value2).valueOf();
      }
      querySoundRecord(params).then(res => {
        if (res.data.code == 200) {
          that.paging.total = res.data.count;
          let list = res.data.data;
          if (list.length > 0) {
            list.forEach(item => {
              item.bool = false;
              item.createAt = this.$moment(item.createAt).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            });
            that.tableData = list;
          } else {
            that.tableData = [];
          }
        } else {
          that.$MessageWarning(res.data.msg);
        }
      });
    },
    getQueryGroupName() {
      //查询群组下拉
      let that = this;
      let data = {
        uId: that.userInfo.id
      };
      queryGroupName(data).then(res => {
        if (res.data.code == 200) {
          let list = res.data.data;
          list.forEach(ele => {
            if (ele.isactive == 1) {
            } else {
              ele.Cg_Name = ele.Cg_Name + "("+this.$t('message.deleted')+")";
            }
          });
          // console.log(list);
          list.unshift({
            Cg_ID: -1,
            Cg_Name: this.$t('message.pleaseselectagroup')
          });
          that.group = list;
        } else {
          that.$MessageWarning(res.data.msg);
        }
      });
    },
    getQueryUserName() {
      //获取用户下拉
      let that = this;
      let data = {
        uId: that.userInfo.id
      };
      queryUserName(data).then(res => {
        if (res.data.code == 200) {
          that.user = res.data.data;
          that.user.unshift({
            User_ID: -1,
            User_Name: this.$t('message.Pleaseselectuser')
          });
        } else {
          that.$MessageWarning(res.data.msg);
        }
      });
    },
    search() {
      //搜索
      this.paging.pageNo = 1;
      if (this.music != null) {
        this.music.pause();
        this.music = null;
      }
      this.refresh();
    },
    eliminateTime() {
      //清除时间
      clearInterval(this.temporaryTime);
      this.timeNum = 0;
      this.temporaryTime = null;
    },
    startTime(row) {
      //开始计时
      let that = this;
      let num = 0;
      let index = that.tableData.findIndex(
        item => item.createAt == row.createAt
      );
      function timerTow() {
        return setInterval(() => {
          num++;
          that.timeNum = num;
          if (that.timeNum >= Math.abs(row.milliSecond / 1000)) {
            that.music = null;
            that.$set(that.tableData[index], "bool", false);
            // that.$refs.history.music = null;
            that.eliminateTime();
          }
        }, 1000);
      }
      that.temporaryTime = timerTow();
    },
    playLy(row, index) {
      //播放录音
      let that = this;
      let show = window.speechSynthesis && window.speechSynthesis.speaking?window.speechSynthesis.speaking:false;
      if(that.$store.state.voiceCallInfo.callType != 0){
            that.$MessageWarning(that.$t('message.message.Voicecallinprogress'));
            return
      }
      if(that.$store.state.videoCallInfo.callType != 0){
            that.$MessageWarning(that.$t('message.message.Videocallinprogress'));
            return
        }
      if (that.$store.state.isSpeech || show || that.$store.state.isBroadcast || that.$store.state.calltThePolice != null) {
        //有人讲话的时候不能播放

        return;
      }
      let recordList = [
        {
          path: row.path,
          codeFormat: 103,
          date: row.createAt
        }
      ];
      let params = {
        recordList: recordList
      };
      queryAudio(JSON.stringify(params)).then(res => {
        if (res.data.code == 200) {
          // console.log(res);
          const filePath = apiAudioUrl + res.data.data.path; // mp3的地址
          let i = that.tableData.findIndex(item => item.bool == true);
          if (i >= 0 && i !== index && that.music != null) {
            //如果上一条有播放则直接播放下一条
            that.music.pause();
            that.music = null;
            that.tableData[i].bool = false;
            that.eliminateTime();
            // that.$set(that.tableData[index], "bool", false);
          }
          if (that.music != null) {
            that.music.pause();
            that.music = null;
            that.tableData[index].bool = false;
            that.eliminateTime();
            // that.$set(that.tableData[index], "bool", false);
          } else {
            that.music = new Audio(filePath);
            that.music.play();
            that.tableData[index].bool = true;
            // that.$set(that.tableData[index], "bool", true);
            that.startTime(row);
          }
          that.$forceUpdate();
        } else {
          that.$MessageWarning(res.data.msg);
        }
      });
    },
    download(row) {
      //下载
      let recordList = [
        {
          path: row.path,
          codeFormat: 103,
          date: row.createAt
        }
      ];
      let params = {
        recordList: recordList
      };
      queryAudio(JSON.stringify(params)).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          const filePath = `${apiAudioUrl}${res.data.data.path}?${Math.random()}`; // mp3的地址
          fetch(filePath)
            .then(res => res.blob())
            .then(blob => {
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.style.display = "none";
              // 使用获取到的blob对象创建的url
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              // 指定下载的文件名
              a.download = `${this.$t('message.Voiceaudio')}.wav`;
              a.click();
              document.body.removeChild(a);
              // 移除blob对象的url
              window.URL.revokeObjectURL(url);
            });
        } else {
          this.$MessageWarning(res.data.msg);
        }
      });
    },
    getSelectiondata(data) {
      //选中的数据
      this.chooselist = data;
      this.excelData = {
        wb: data.filter(item => item.MSG_TYPE == 1),
        ly: data.filter(item => item.MSG_TYPE != 1)
      };
    },
    dowloan() {
      //批量下载
      if (this.chooselist.length != 0) {
        this.showcontent = true;
        this.tipstext = this.$t('message.Areyousureyouwant');
      } else {
        this.$MessageWarning(this.$t('message.Pleaseselecttherecording'));
      }
    },
    closepop() {
      this.showcontent = false;
    },
    confirmclosepop() {
      //批量下载
      let recordList = [];
      this.chooselist.forEach(element => {
        let obj = {};
        obj.path = element.path;
        obj.codeFormat = 103;
        obj.date = element.createAt;
        recordList.push(obj);
      });
      let params = {
        recordList: recordList
      };
      queryAudio(JSON.stringify(params)).then(res => {
        if (res.data.code == 200) {
          this.showcontent = false;
          // 使用获取到的blob对象创建的url
          const filePath = apiAudioUrl + "/" + res.data.data.path; // mp3的地址
          let downloadURL = res.data.data.path.substring(
            res.data.data.path.length - 3
          );
          fetch(filePath)
            .then(res => res.blob())
            .then(blob => {
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.style.display = "none";
              // 使用获取到的blob对象创建的url
              const url = window.URL.createObjectURL(blob);
              console.log("url", url);
              a.href = url;
              // 指定下载的文件名
              a.download = `${this.$t('message.Voiceaudio')}.${downloadURL}`;
              a.click();
              document.body.removeChild(a);
              // 移除blob对象的url
              window.URL.revokeObjectURL(url);
            });
          this.$message({
            message: this.$t('message.downloadsuccessful'),
            type: "success",
            center: true,
            offset: 450
          });
        } else {
          this.$MessageWarning(res.data.msg);
        }
      });
    },
    refresh() {
      //刷新
      this.getSoundRecord();
    },
    comfir() {
      //确定按钮
      this.refresh();
    },
    sizeChange(pageSize) {
      //当前页数据发生改变时
      this.paging.pageSize = pageSize;
      this.refresh();
    },
    prevClick(pageNum) {
      //前进按钮
      this.paging.pageNo = pageNum;
      this.refresh();
    },
    nextClick(pageNum) {
      //退后按钮
      this.paging.pageNo = pageNum;
      this.refresh();
    },
    currentChange(pageNum) {
      //当前页发生改变时
      this.paging.pageNo = pageNum;
      this.refresh();
    },
    muteFun() {      //静音
        let i = this.tableData.findIndex(item => item.bool == true);
        if (i >= 0) {
          this.$set(this.tableData[i], "bool", false);
          // this.$forceUpdate()
          this.eliminateTime();
          this.music.pause();
          this.tableData[i].bool = false;
          this.music = null;
        }
      }
  },
  watch: {
    //监听事件
    groupId(val) {
      if (val == -1) {
        this.groupId = "";
      }
    },
    userId(val) {
      if (val == -1) {
        this.userId = "";
      }
    },
    "$store.state.isSpeech"(newVal) {
      //对数据执行操作
      if (newVal) {
        this.muteFun();
      }
    },
    "$store.state.sosPushInfo"(newVal) {        //sos
            //对数据执行操作
            if (newVal.length > 0) {
              this.muteFun();
            }
        },
        "$store.state.gbInfoText"(newVal) {        //文字广播
            //对数据执行操作
            if (newVal.length > 0) {
              this.muteFun();
            }
        },
        "$store.state.isTextBb"(bool) {        //文字广播
            //对数据执行操作
            if (bool == true) {
              this.muteFun();
            }
        },
        "$store.state.isBroadcast"(bool) {        //语音广播
            //对数据执行操作
            if (bool == true) {
              this.muteFun();
            }
        },
        "$store.state.calltThePolice"(newVal) {        
            //对数据执行操作
            if (newVal != null) {
              this.muteFun();
            }
        },
        "$store.state.videoCallInfo.callType"(type) {
            if (type != 0) {
                this.muteFun();
            }
        },
        "$store.state.voiceCallInfo.callType"(type) {
            if (type != 0) {
                this.muteFun();
            }
        }
  }
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #ffffff;

  &_container {
    width: calc(100% - 20px);
    height: 100%;
    margin: auto;

    .head {
      width: 100%;
      padding: 10px 0px;
      background-color: #f0f0f0;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      font-size: 15px;
      color: #5a5655;

      &_container {
        flex: 1;
        margin-left: 10%;
      }

      &_dropDown {
        font-size: 15px;
        color: #5a5655;
        margin-right: 20px;

        span {
          margin-right: 5px;
        }
      }

      &_select {
        width: 160px;
        height: 40px;
      }

      &_picker {
        width: 280px !important;
        text-align: center;
      }

      &_search {
        width: 70px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        // background: url(/img/leftBg1.81e6fd48.png);
        background-color: #4d4d4d;
        background-size: 100% 100%;
        border-radius: 5px;
        color: #ffffff !important;
      }

      &_but {
        width: 110px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: var(--main-color);
        border-radius: 3px;
        color: #ffffff !important;
        margin-right: 5px;
      }
    }

    .table_one {
      height: calc(100vh - 73px - 145px);
    }

    .table_tow {
      height: calc(100% - 235px);
    }

    .table {
      width: 100%;
      background-color: #f0f0f0;
      overflow: auto;
      color: #000000;

      &_box {
        width: 100%;
        text-align: center;
      }

      &_but {
        color: var(--main-color);
        margin-right: 10px;
      }
    }

    .pagination {
      width: 100%;
      // height: 80px;
      text-align: left;
      position: relative;
      background-color: #ffffff;

      /deep/.el-pagination__jump {
        margin-left: 0;
      }

      .tip-sos {
        text-align: center;
        color: #b2b2b2;
        height: 30px;
        line-height: 30px;
      }

      &_bottom {
        padding: 15px 0px;
        // position: absolute;
        // bottom: 0px;
        // left: 0px;
      }

      .confirbtn {
        background-color: #5a5657;
        padding: 0 6px;
        color: #ffffff;
        margin-left: 4px;
        cursor: pointer;
        border-radius: 2px;
        // line-height: 14px;
      }
    }
  }
}

.video-box {
  width: 100%;
  height: 480px;
}

vidio {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bg_one {
  color: #ffffff;
  background: url("../../assets/history/select.png");
}

.bg_tow {
  color: #000000;
  background: url("../../assets/history/select1.png");
}

/deep/.el-input__icon {
  display: block !important;
}

.flex {
  display: flex;
  align-items: center;
}
</style>
